import React from 'react';
import { Link } from "react-router-dom";
import InvflyLogo from '../assets/img/Invfly-Logo-PNG.png'

function Navbar() {
  return (
    <div className="container-fluid p-0">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5">
        <Link href="index.html" to="/" className="navbar-brand ml-lg-3">
          <img src={InvflyLogo} alt="Invfly Logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-between px-lg-3" id="navbarCollapse">
          <div className="navbar-nav mx-auto py-0">
            <Link to="/" className="nav-item nav-link active">Home</Link>
            <li className="nav-item dropdown">
              <Link to="/programs" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Programs
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/programs/pro-trader-program" className="dropdown-item">Invfly Pro Trader Program</Link>
                <Link to="/programs/swing-trader-program" className="dropdown-item">Invfly Swing Trader Program</Link>
                <Link to="/programs/financial-literacy-program" className="dropdown-item">Financial Literacy Program</Link>
                <Link to="/programs/wealth-management-program" className="dropdown-item">Wealth Management</Link>
              </div>
            </li>
            <Link to="/" className="nav-item nav-link">Tools</Link>
            <Link to="/" className="nav-item nav-link">Blogs</Link>
            <Link to="/about" className="nav-item nav-link">About Us</Link>
            <Link to="/" className="nav-item nav-link">Login</Link>
          </div>
          {/* <Link to="/" className="btn btn-primary py-2 px-4 d-none d-lg-block">Join Us</Link> */}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
