import React from "react";
import Topbar from '../../components/Topbar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import swingTradingImage from '../../assets/img/swing-trader.webp';

export default function SwingTraderProgram() {
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <img src={swingTradingImage} className="img-fluid mb-5 program-image" alt="Swing Trading Program" />
            <h3 className="mb-3 title">Invfly Swing Trading Program</h3>
            <div className="quote-banner">
              <h5 className="lead quote"><i className="fas fa-quote-left fa-xs text-info "></i> Trade with Confidence, even with a Busy Schedule <i className="fas fa-quote-right fa-xs text-info"></i></h5>
            </div>
            <p className="description"><strong className='text-info'>Invfly</strong> Swing Trading Program equips employees and working professionals with the tools and strategies to navigate the stock market effectively, even amidst demanding work schedules.</p>
            <ul className="points">
              <li className="point">Emphasize the program's flexible learning format, catering to individuals with limited time.</li>
              <li className="point">Focus on the program's streamlined approach, allowing participants to learn swing trading strategies efficiently.</li>
              <li className="point">Clear and concise lessons that avoid unnecessary complexities while taking trades.</li>
              <li className="point">Tools and Resources for getting best returns without following regularly in the markets.</li>
              <li className="point">The program is emphasized on user-friendly Tools and Techniques, even for individuals with no prior trading experience.</li>
            </ul>
            <h2 className="mt-4">This program is designed for individuals who are:</h2>
            <ul className="points">
              <li className="point">For people who are in full time employment ( those people who are not able to track markets regularly during the markets hours)</li>	
              <li className="point">Willing to earn extra income without talking too much risk and stress.</li>	
              <li className="point">Wiling to create extra income form trading.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}