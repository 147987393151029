import React from "react";
import Topbar from './../components/Topbar';
import Navbar from './../components/Navbar';
import HeaderSection from './../components/HeaderSection';
import ContactList from './../components/ContactList';
import Footer from './../components/Footer';

export default function AboutUs() {
  return (
    <>
        <Topbar />
        <Navbar />
        <ContactList />
        <Footer />
    </>
  );
}
