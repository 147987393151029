import React from "react";
import Topbar from '../../components/Topbar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import wealthManagementImage from '../../assets/img/wealth-management.webp';

export default function WealthManagementService() {
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <img src={wealthManagementImage} className="img-fluid mb-5 program-image" alt="Wealth Management Program" />
            <h3 className="mb-3 title">Invfly Wealth Management Service</h3>
            <div className="quote-banner">
              <h5 className="lead quote"><i className="fas fa-quote-left fa-xs text-info "></i> Customized wealth management services for better returns <i className="fas fa-quote-right fa-xs text-info"></i></h5>
            </div>
            <p className="description"><strong className='text-info'>Invfly</strong> Wealth Management Program offers customized services based on individual needs and risk levels, which significantly gives better returns from the markets.</p>
            <ul className="points">
              <li className="point">Customized wealth management services</li>
              <li className="point">Services based on individual needs and risk levels</li>
              <li className="point">Significantly better returns from the markets</li>
            </ul>
            <h2 className="mt-4">This program is designed for individuals who are:</h2>
            <ul className="points">
              <li className="point"> <strong className='text-info'>Serious about wealth management:</strong> Committed to learning and actively pursuing wealth management as a potential income source.</li>
              <li className="point"> <strong className='text-info'>Willing to dedicate time and effort:</strong> Wealth management requires consistent learning, practice, and discipline.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}