import React from 'react';
import { Link } from "react-router-dom";

export default function HeaderSection({showTitle = true, showButton = true}) {
  return (
    <div className="jumbotron jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: '90px' }}>
      <div className="container text-center my-5 py-5">
        <h1 className="text-white mt-4 mb-4">Empowering Individuals with Financial Education</h1>
        {showTitle && <h1 className="text-white display-1 mb-5">Explore Our Courses</h1> } 
        {showButton && <div className="mx-auto mb-5" style={{ width: '100%', maxWidth: '600px' }}>
          <div className="input-group-append d-flex justify-content-center">
            <Link to="/programs">
              <button className="btn btn-secondary px-4 px-lg-5 btn-rounded">Explore Programs</button>
            </Link>
          </div>
        </div>
        }
      </div>
    </div>
  );
}