import React from 'react';
import trainerNarasimhaswamyImage from '../assets/img/trainer_narasimhaswamy_image.png'
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function Instructors() {
  const settings = {
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    speed: 1000,
    items: 1,
    autoplay: true,
    loop: true,
  responsive: {
      0:{
          items:1
      }
  }
  };

  return (
    <div className="container-fluid bg-light py-5">
      <div className="container py-5">
        <div className="section-title text-center position-relative mb-5">
          <h6 className="text-secondary text-uppercase">Trainers</h6>
          <h2 className="display-4">Meet Our Professional Trainer</h2>
        </div>
        <TinySlider className="team-carousel position-relative" style={{ padding: '0 30px'}} settings={settings}>
          <div className="team-item">
            <img className="img-fluid w-30 mx-auto d-block" src={trainerNarasimhaswamyImage} alt="Trainer Narasimhaswamy" />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3"> CA B Narasimhaswamy </h5>
              <p className="mb-2 ">A seasoned professional with 7 years of rich experience in both academics and industry. With expertise in financial modelling, technical analysis, and fundamental analysis, he has honed his skills through extensive practical application and research. He served as a professor and trainer in diverse universities and institutions, he brings a unique blend of theoretical knowledge and real-world insights to his teaching and training sessions. His financial concepts coupled with hands-on experience in industry practices males him valuable asset in the realm in finance education. His commitment to excellence and passion for sharing knowledge has earned him recognition among peers and students alike. Whether in the classroom or during training workshops, he excels in simplifying complex concepts making them accessible to learners of all backgrounds.</p>
              <div className="d-flex justify-content-center">
                <a className="mx-1 p-1" href="https://twitter.com/invfly"><i className="fab fa-twitter"></i></a>
                <a className="mx-1 p-1" href="https://www.linkedin.com/company/invfly"><i className="fab fa-linkedin-in"></i></a>
                <a className="mx-1 p-1" href="https://www.instagram.com/invfly/"><i className="fab fa-instagram"></i></a>
                <a className="mx-1 p-1" href="https://www.youtube.com/@invfly"><i className="fab fa-youtube"></i></a>
              </div>
            </div>
          </div>
        </TinySlider>
      </div>
    </div>
  );
}
