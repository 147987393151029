
import React,{useEffect} from 'react'
import $ from 'jquery'
export default function Topbar() {
    useEffect(() => {
        $(document).ready(function () {
            function toggleNavbarMethod() {
                if ($(window).width() > 992) {
                    $('.navbar .dropdown').on('mouseover', function () {
                        $('.dropdown-toggle', this).trigger('click');
                    }).on('mouseout', function () {
                        $('.dropdown-toggle', this).trigger('click').blur();
                    });
                } else {
                    $('.navbar .dropdown').off('mouseover').off('mouseout');
                }
            }
            toggleNavbarMethod();
            $(window).resize(toggleNavbarMethod);
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });

        $('.back-to-top').click(function () {
            $('html, body').animate({scrollTop: 0}, 1500, 'easeInOutExpo');
            return false;
        });

    }, [])
  return (
    <div className="container-fluid bg-dark">
        <div className="row py-2 px-lg-5">
            <div className="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center text-white">
                    <small><i className="fa fa-phone-alt mr-2"></i>+91 9381167479</small>
                    <small className="px-3">|</small>
                    <small><i className="fa fa-envelope mr-2"></i>info@invfly.com</small>
                </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                    <a className="text-white px-2" href="/" disable={true}>
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-white px-2" href="https://twitter.com/invfly"  target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="text-white px-2" href="https://www.linkedin.com/company/invfly" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a className="text-white px-2" href="https://www.instagram.com/invfly/" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="text-white pl-2" href="https://www.youtube.com/@invfly" target="_blank" rel="noreferrer">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
}
