import React from 'react';
import { Fade } from 'react-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import trainerNarasimhaswamyImage from '../assets/img/trainer_narasimhaswamy_image.png'
import { faBullhorn, faBullseye, faEye } from '@fortawesome/free-solid-svg-icons';

export default function About() {
  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row">
          <Fade left>
            <div className="col-12 mb-5 mb-lg-0 d-flex">
              <div className="card shadow-lg mb-3 flex-grow-1 d-flex flex-column material-card">
                <div className="card-body flex-grow-1">
                  <h1 className="card-title text-dark m-3"><FontAwesomeIcon icon={faBullhorn} size="sm" /> About InvFly </h1>
                  <p className="card-text text-justify p-3"><strong className='text-info'> Invfly </strong> is dedicated to empowering individuals to achieve their financial goals through education, guidance, and access to the opportunities of the stock market. We believe in the power of financial literacy and aim to equip individuals with the knowledge and skills necessary to make informed investment decisions as well as wealth creation.</p>
                </div>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="col-12 d-flex flex-column">
              <div className="card shadow-lg mb-3 flex-grow-1 d-flex flex-column material-card">
                <div className="row no-gutters">
                  <div className="col-md-4">
                    <img src={trainerNarasimhaswamyImage} className="card-img" alt="Co-founder" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body flex-grow-1">
                      <h1 className="card-title text-dark m-3">Founder</h1>
                      <h3 className="card-title text-dark m-3">CA B Narasimhaswamy</h3>
                      <p className="card-text text-justify p-3">A seasoned professional with 7 years of rich experience in both academics and industry. With expertise in financial modelling, technical analysis, and fundamental analysis, he has honed his skills through extensive practical application and research. He served as a professor and trainer in diverse universities and institutions, he brings a unique blend of theoretical knowledge and real-world insights to his teaching and training sessions. His financial concepts coupled with hands-on experience in industry practices males him valuable asset in the realm in finance education. His commitment to excellence and passion for sharing knowledge has earned him recognition among peers and students alike. Whether in the classroom or during training workshops, he excels in simplifying complex concepts making them accessible to learners of all backgrounds.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
            <div className="col-12 d-flex flex-column">
            <Fade left>
              <div className="card shadow-lg mb-3 flex-grow-1 d-flex flex-column material-card">
                <div className="card-body flex-grow-1 ">
                  <h1 className="card-title text-dark m-3"><FontAwesomeIcon icon={faBullseye} size="sm" /> Mission</h1>
                  <p className="card-text text-justify p-3">Our mission is to <mark><b>democratize financial literacy and stock market participation</b></mark>, making valuable resources and opportunities accessible to individuals of all backgrounds and experience levels. We strive to foster a <mark><b>community of informed and empowered</b></mark> investors who can navigate the complexities of the financial markets with confidence.</p>
                </div>
              </div>
              </Fade>
              <Fade right>
              <div className="card shadow-lg flex-grow-1 d-flex flex-column material-card">
                <div className="card-body flex-grow-1">
                  <h1 className="card-title text-dark m-3"> <FontAwesomeIcon icon={faEye} size="sm" /> Vision</h1>
                  <p className="card-text text-justify p-3">We envision a future where <mark><b>financial literacy is a cornerstone of personal empowerment</b></mark>, enabling individuals to <mark><b> achieve financial security and independence. </b></mark>We believe that by fostering knowledge and responsible investing practices, we can contribute to a <mark><b> more stable and prosperous economy.</b></mark></p>
                </div>
              </div>
              </Fade>
            </div>
        </div>
      </div>
    </div>
  );
}