import React from 'react';
import Topbar from './../components/Topbar';
import Navbar from './../components/Navbar';
import Footer from './../components/Footer';
import { Link } from 'react-router-dom';
import proTraderImage from '../assets/img/pro-trader.webp';
import swingTraderImage from '../assets/img/swing-trader.webp';
import financialLiteracyImage from '../assets/img/financial-literacy.webp';
import wealthManagementImage from '../assets/img/wealth-management.webp';

const programsData = [
    {
        image: proTraderImage,
        title: "Invfly Pro Trader Program",
        description: "Unleash Your Inner Trader with the Invfly Pro Trader Program",
        link: "/programs/pro-trader-program"
    },
    {
        image: swingTraderImage,
        title: "Invfly Swing Trader Program",
        description: "Empowering Employees and Working Professionals",
        link: "/programs/swing-trader-program"
    },
    {
        image: financialLiteracyImage,
        title: "Financial Literacy Program",
        description: "Empower Your Financial Future Invfly's Financial Literacy Services",
        link: "/programs/financial-literacy-program"
    },
    {
        image: wealthManagementImage,
        title: "Wealth Management Service",
        description: "The customized wealth management services",
        link: "/programs/wealth-management-program"
    }
];

const Programs = () => {
    return (
        <>
            <Topbar />
            <Navbar />
            <div className="container py-5">
                <h2 className="text-center mb-5">Programs</h2>
                <p className='mb-6'>We are offering different programs for our participants based on their requirements and time they are going to allocate for the trading on daily basis</p>
                <div className="row mb-5">
                    {programsData.map((program, index) => (
                        <div className="col-lg-6 mb-4" key={index}>
                            <div className="card shadow-lg h-100">
                                <img src={program.image} className="card-img-top p-1" alt={program.title} />
                                <div className="card-body">
                                    <h5 className="card-title">{program.title}</h5>
                                    <p className="card-text">{program.description}</p>
                                    <div className="text-right">
                                        <Link to={program.link} className="btn btn-primary stretched-link btn-rounded">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Programs;
