import React from "react";
import Topbar from './../components/Topbar';
import Navbar from './../components/Navbar';
import HeaderSection from './../components/HeaderSection';
import Feature from './../components/Feature';
import Programs from './../components/Programs';
import Instructors from '../components/Trainers';
import Testimonials from './../components/Testimonials';
import Contact from './../components/Contact';
import Footer from './../components/Footer';

export default function Home() {
  return (
    <>
        <Topbar />
        <Navbar />
        <HeaderSection />
        <Feature />
        <Programs />
        <Instructors />
        <Testimonials />
        <Contact />
        <Footer />
    </>
  );
}
