import React, { useState } from 'react';
import { baseURL } from '../config';
import Swal from 'sweetalert2'

export default function ContactList() {
  const [rows, setRows] = useState([]);

  const [formData, setFormData] = useState({
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jsonData = JSON.stringify(formData);
      const response = await fetch(`${baseURL}/list-contact-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonData
      });
      if (response.ok) {
        Swal.fire({
          title: "Password Granted",
          icon: "success"
        });
        response.json().then(data => setRows(data.data));
      } else {
        Swal.fire();
        Swal.fire({
          title: "Access Denied",
          icon: "error"
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="container-fluid py-5">
      <div className="container py-5">

        {rows.length > 0 ? (<table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Datetime</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Subject</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            {
              rows && rows.map((eachRow, index) => (
                <tr key={index}>
                  <th scope="row">{eachRow.datetime}</th>
                  <td>{eachRow.name}</td>
                  <td>{eachRow.email}</td>
                  <td>{eachRow.subject}</td>
                  <td>{eachRow.message}</td>
                </tr>
              ))
            }
          </tbody>
        </table>) : (<div className="col-lg-7">
          <div className="section-title position-relative mb-4">
            <h1 className="display-4">Access Required</h1>
          </div>
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6 form-group">
                  <input
                    type="password"
                    className="form-control border-top-0 border-right-0 border-left-0 p-0"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div>
                <button className="btn btn-success btn-sm btn-rounded py-3 px-5" type="submit">Verify</button>
              </div>
            </form>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
