import React from 'react';
import picTestimonial1 from '../assets/img/testimonial-1.jpg'
import picTestimonial2 from '../assets/img/testimonial-2.jpg'
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function Testimonials() {
  const settings = {
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    speed: 1500,
    items: 1,
    slideBy: 'page',
    autoplay: true,
    loop: true
  };
  return (
    <div className="container-fluid bg-image py-5" style={{ margin: '90px 0' }}>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="section-title position-relative mb-4">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">Testimonials</h6>
              <h2 className="display-4">What Our Traders Say</h2>
            </div>
            <p className="m-0">Our traders appreciate the hands-on approach we offer. With our advanced trading journal, they can meticulously record their trades and analyze their performance. We evaluate their progress and provide personalized feedback to help them improve. Our traders undergo a thorough evaluation period before venturing into live trading, ensuring they are well-prepared to apply the strategies they've learned. Moreover, we focus on developing the mental fortitude and emotional resilience necessary to navigate the market's ups and downs. Our supportive community provides a platform for traders to share experiences, learn from one another, and grow together.</p>
          </div>
          <div className="col-lg-7">
            <TinySlider className="owl-carousel testimonial-carousel" settings={settings}>
              <div className="bg-white p-5">
                <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
                <p>Our trading journal has been invaluable in helping me track my progress and identify areas for improvement. The personalized feedback I received has been instrumental in refining my trading strategies. Thanks to the thorough evaluation process, I felt confident transitioning to live trading. The emphasis on trading psychology has been eye-opening and has helped me manage my emotions better. The supportive community has provided me with invaluable insights and encouragement along the way.</p>
                <div className="d-flex flex-shrink-0 align-items-center mt-4">
                  <img className="img-fluid mr-4" src={picTestimonial1} alt="" />
                  <div>
                    <h5>Trader Name</h5>
                    <span>Day Trading</span>
                  </div>
                </div>
              </div>
              <div className="bg-white p-5">
                <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
                <p>I've been thoroughly impressed with the level of support and guidance provided by the instructors. The trading journal has helped me stay organized and focused on my trading goals. The evaluation process has been rigorous but incredibly beneficial in honing my skills. Learning about trading psychology has been transformative, and I now feel more confident in my decision-making abilities. Being part of such a supportive community has been invaluable on this journey.</p>
                <div className="d-flex flex-shrink-0 align-items-center mt-4">
                  <img className="img-fluid mr-4" src={picTestimonial2} alt="" />
                  <div>
                    <h5>Trader Name</h5>
                    <span>Day Trading</span>
                  </div>
                </div>
              </div>
              <div className="bg-white p-5">
                <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
                <p>The hands-on approach provided by this course has been fantastic. The trading journal has helped me track my progress and identify areas where I can improve. The instructors are incredibly knowledgeable and provide valuable feedback that has helped me refine my trading strategies. The emphasis on trading psychology has been particularly helpful in developing the mindset needed for success in trading. And the community support has been invaluable, providing a platform for learning and growth.</p>
                <div className="d-flex flex-shrink-0 align-items-center mt-4">
                  <img className="img-fluid mr-4" src={picTestimonial1}  alt="" />
                  <div>
                    <h5>Trader Name</h5>
                    <span>Day Trading</span>
                  </div>
                </div>
              </div>
            </TinySlider>
          </div>
        </div>
      </div>
    </div>
  );
}
