import React,{ useState } from 'react';
import { baseURL  } from '../config';
import Swal from 'sweetalert2'

export default function Contact() {
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const jsonData = JSON.stringify(formData);
            const response = await fetch(`${baseURL}/submit-contact-form`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: jsonData
          });
          if (response.ok) {
            Swal.fire({
              title: "Your form submitted sucessfully. Thank you for contacting us",
              icon: "success"
            });
            } else {
            Swal.fire();
            Swal.fire({
              title: "Your form submission failed. Please try again.",
              icon: "error"
            });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="bg-light d-flex flex-column justify-content-center px-5" style={{ height: '450px' }}>
              <div className="d-flex align-items-center mb-5">
                <div className="btn-icon bg-primary mr-4">
                  <i className="fa fa-2x fa-map-marker-alt text-white"></i>
                </div>
                <div className="mt-n1">
                  <h4>Our Location</h4>
                  <p className="m-0">Reg Office – H.NO 2-48/23, Telecom Nagar, Gachibowli, Telangana -500032</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-5">
                <div className="btn-icon bg-secondary mr-4">
                  <i className="fa fa-2x fa-phone-alt text-white"></i>
                </div>
                <div className="mt-n1">
                  <h4>Call Us</h4>
                  <p className="m-0">+91 9381167479</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="btn-icon bg-warning mr-4">
                  <i className="fa fa-2x fa-envelope text-white"></i>
                </div>
                <div className="mt-n1">
                  <h4>Email Us</h4>
                  <p className="m-0">info@invfly.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-title position-relative mb-4">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">Need Help?</h6>
              <h1 className="display-4">Send Us A Message</h1>
            </div>
            <div className="contact-form">
            <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-6 form-group">
                    <input
                        type="text"
                        className="form-control border-top-0 border-right-0 border-left-0 p-0"
                        placeholder="Your Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-6 form-group">
                    <input
                        type="email"
                        className="form-control border-top-0 border-right-0 border-left-0 p-0"
                        placeholder="Your Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    className="form-control border-top-0 border-right-0 border-left-0 p-0"
                    placeholder="Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div className="form-group">
                <textarea
                    className="form-control border-top-0 border-right-0 border-left-0 p-0"
                    rows="5"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                ></textarea>
            </div>
            <div>
                <button className="btn btn-primary py-3 px-5" type="submit">Send Message</button>
            </div>
        </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
