import React from 'react';
import InvflyLogo from '../assets/img/Invfly-Logo-PNG.png'
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid position-relative overlay-top bg-dark text-white-50 py-5" style={{ marginTop: '90px' }}>
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-md-6 mb-5">
              <Link to="/" className="navbar-brand">
                <img src={InvflyLogo} alt="Invfly Logo" />
              </Link>
              <p className="m-0">Invfly is a company dedicated to empowering individuals to achieve their financial goals through education, guidance, and access to the opportunities of the stock market. We believe in the power of financial literacy and aim to equip individuals with the knowledge and skills necessary to make informed investment decisions as well as wealth creation.</p>
            </div>
            <div className="col-md-6 mb-5">
              <h3 className="text-white mb-4">Newsletter</h3>
              <div className="w-100">
                <div className="input-group">
                  <input type="text" className="form-control border-light" style={{ padding: '30px' }} placeholder="Your Email Address" />
                  <div className="input-group-append">
                    <button className="btn btn-primary px-4">Sign Up</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-5">
              <h3 className="text-white mb-4">Get In Touch</h3>
              <p><i className="fa fa-map-marker-alt mr-2"></i>Reg Office – H.NO 2-48/23, Telecom Nagar, Gachibowli, Telangana -500032</p>
              <p><i className="fa fa-phone-alt mr-2"></i>+91 9381167479</p>
              <p><i className="fa fa-envelope mr-2"></i>info@invfly.com</p>
              <div className="d-flex justify-content-start mt-4">
                <a className="text-white mr-4" href="https://twitter.com/invfly" target="_blank" rel="noreferrer" ><i className="fab fa-2x fa-twitter"></i></a>
                <a className="text-white mr-4" href="https://www.youtube.com/@invfly" target="_blank" rel="noreferrer" ><i className="fab fa-2x fa-youtube"></i></a>
                <a className="text-white mr-4" href="https://www.linkedin.com/company/invfly" target="_blank" rel="noreferrer" ><i className="fab fa-2x fa-linkedin-in"></i></a>
                <a className="text-white" href="https://www.instagram.com/invfly/" target="_blank" rel="noreferrer" ><i className="fab fa-2x fa-instagram"></i></a>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <h3 className="text-white mb-4">Explore Our Programs</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link className="text-white-50 mb-2" to="/pro-trader-program"><i className="fa fa-angle-right mr-2"></i>Invfly Pro Trader Program</Link>
                <Link className="text-white-50 mb-2" to="/swing-trader-program"><i className="fa fa-angle-right mr-2"></i>Invfly Swing Trader Program</Link>
                <Link className="text-white-50 mb-2" to="/financial-literacy-program"><i className="fa fa-angle-right mr-2"></i>Financial Literacy Program</Link>
                <Link className="text-white-50 mb-2" to="/wealth-management"><i className="fa fa-angle-right mr-2"></i>Wealth Management</Link>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <h3 className="text-white mb-4">Useful Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link className="text-white-50 mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>Privacy Policy</Link>
                <Link className="text-white-50 mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>Terms & Condition</Link>
                <Link className="text-white-50 mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>Regular FAQs</Link>
                <Link className="text-white-50 mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>Help & Support</Link>
                <Link className="text-white-50" to="/"><i className="fa fa-angle-right mr-2"></i>Contact</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white-50 border-top py-4" style={{ borderColor: 'rgba(256, 256, 256, .1) !important' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-left mb-3 mb-md-0">
              <p className="m-0">Copyright &copy; <Link className="text-white" to="/">InvFly</Link>. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
