import React from "react";
import Topbar from '../../components/Topbar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import financialLiteracyImage from '../../assets/img/financial-literacy.webp';

export default function FinancialLiteracyProgram() {
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <img src={financialLiteracyImage} className="img-fluid mb-3 program-image" alt="Financial Literacy Program" />
            <h3 className="mb-3 title">Empower Your Financial Future: Invfly's Financial Literacy Services</h3>
            <div className="quote-banner">
              <h5 className="lead quote"><i className="fas fa-quote-left fa-xs text-info "></i> Invest in Yourself: Unlock Your Financial Potential with Invfly <i className="fas fa-quote-right fa-xs text-info"></i></h5>
            </div>
            <p className="description">Navigate the world of finance with confidence. Invfly offers a comprehensive suite of financial literacy services designed to empower individuals of all backgrounds and experience levels to make informed financial decisions and achieve their financial goals.</p>
            <ul className="points">
              <li className="point">Covering various financial topics like budgeting, investing, debt management, and retirement planning through webinars.</li>
              <li className="point">Emphasize the customized guidance and support tailored to individual circumstances and goals.</li>
            </ul>
            <h2 className="mt-4">This program is designed for the Colleges/Universities for guiding the students regarding the benefits of financial literacy and how to save money for future.</h2>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}