import React from 'react';
import picCourses1 from '../assets/img/courses-1.jpg'
import picCourses2 from '../assets/img/courses-2.jpg'
import picCourses3 from '../assets/img/courses-3.jpg'
import picCourses4 from '../assets/img/courses-4.jpg'
import picCourses5 from '../assets/img/courses-5.jpg'
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function Programs() {
  const settings = {
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    speed: 1500,
    items: 3,
    slideBy: 'page',
    autoplay: true,
    loop: true,
    responsive: {
      0:{
          items:1
      },
      576:{
          items:2
      },
      768:{
          items:3
      },
      992:{
          items:4
      }
  }
  };
  
  return (
    <div className="container-fluid bg-light py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-5">
              <h6 className="text-secondary text-uppercase">Our Courses</h6>
              <h2 className="display-4">Checkout New Releases Of Our Courses</h2>
            </div>
          </div>
        </div>
        <TinySlider className="courses-carousel" settings={settings}>
          <div className="courses-item position-relative">
            <img className="img-fluid" src={picCourses1} alt="" />
            <div className="courses-text">
              <h4 className="text-center text-white px-3">Day Trading Fundamentals</h4>
              <p className="text-white px-3">Master the core concepts of day trading, including market structure, order types, and chart analysis.</p>
            </div>
          </div>
          <div className="courses-item position-relative">
            <img className="img-fluid" src={picCourses2} alt="" />
            <div className="courses-text">
              <h4 className="text-center text-white px-3">Technical Analysis</h4>
              <p className="text-white px-3">Uncover the secrets of technical analysis, learning to interpret charts, identify trading signals, and make informed decisions based on price action and technical indicators.</p>
            </div>
          </div>
          <div className="courses-item position-relative">
            <img className="img-fluid" src={picCourses3} alt="" />
            <div className="courses-text">
              <h4 className="text-center text-white px-3">Day Trading Strategies for Profits</h4>
              <p className="text-white px-3">Explore a diverse range of day trading strategies that allow you to find the approach that best suits your trading style and risk tolerance levels.</p>
            </div>
          </div>
          <div className="courses-item position-relative">
            <img className="img-fluid" src={picCourses4} alt="" />
            <div className="courses-text">
              <h4 className="text-center text-white px-3">Advanced Concepts</h4>
              <p className="text-white px-3">Delve deeper into advanced topics like risk management, position sizing, and options trading, enabling you to refine your trading strategies and manage risk effectively.</p>
            </div>
          </div>
          <div className="courses-item position-relative">
            <img className="img-fluid" src={picCourses5} alt="" />
            <div className="courses-text">
              <h4 className="text-center text-white px-3">Live Market Experience</h4>
              <p className="text-white px-3">Gain valuable practical experience through simulated and live trading sessions, putting your knowledge into action under real-time market conditions.</p>
            </div>
          </div>
        </TinySlider>
        <div className="row justify-content-center bg-image mx-0 mb-5">
          <div className="col-lg-6 py-5">
            <div className="bg-white p-5 my-5">
              <h1 className="text-center mb-4">30% Off For New Learners</h1>
              <form>
                <div className="form-row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input type="text" className="form-control bg-light border-0" placeholder="Your Name" style={{ padding: '30px 20px' }} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input type="email" className="form-control bg-light border-0" placeholder="Your Email" style={{ padding: '30px 20px' }} />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <select className="custom-select bg-light border-0 px-3" style={{ height: '60px' }}>
                        <option selected>Select A Course</option>
                        <option value="1">Financial Literacy</option>
                        <option value="2">Pro Trader</option>
                        <option value="3">Swing Trader</option>
                        <option value="3">Wealth Management</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <button className="btn btn-primary btn-block" type="submit" style={{ height: '60px' }}>Sign Up Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
