import { React, useEffect, useState } from "react";
import './App.css';
import './css/Program.css'

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// PAGES
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Programs from "./pages/Programs";
import FinancialLiteracy from "./pages/programs/FinancialLiteracyProgram";
import ProTrader from "./pages/programs/ProTraderProgram";
import SwingTrader from "./pages/programs/SwingTraderProgram";
import WealthManagement from "./pages/programs/WealthManagementService";
import ContactList from "./pages/ContactList";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); 
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    isLoading ? <div className="invfly-loading-animation"></div> : 
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/programs/financial-literacy-program" element={<FinancialLiteracy />} />
        <Route path="/programs/pro-trader-program" element={<ProTrader />} />
        <Route path="/programs/swing-trader-program" element={<SwingTrader />} />
        <Route path="/programs/wealth-management-program" element={<WealthManagement />} />
        <Route path="/contact-list" element={<ContactList />} />
      </Routes>
    </Router>
  );
}

export default App;