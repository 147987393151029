import React from 'react';
import FeatureImg from '../assets/img/feature.jpg'


export default function Feature() {
  return (
    <div className="container-fluid bg-image" style={{ marginTop: '90px' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 my-5 pt-5 pb-lg-5">
            <div className="section-title position-relative mb-4">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">Why Choose Us?</h6>
              <h1 className="display-4">Why Choose Invfly?</h1>
            </div>
            <p className="mb-4 pb-2">Invfly is a team of Chartered Accountants and MBAs from renowned universities, with over 10 years of expertise in the financial markets. Our team specializes in technical analysis techniques for consistent returns from stock markets and is adept in Risk Management, which is crucial for long-term sustainability in the stock markets.</p>
            <div className="d-flex mb-3">
              <div className="btn-icon bg-primary mr-4">
                <i className="fa fa-2x fa-graduation-cap text-white"></i>
              </div>
              <div className="mt-n1">
                <h4>Skilled Instructors</h4>
                <p>Our instructors are highly skilled and experienced in their respective fields, ensuring you receive top-notch guidance.</p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="btn-icon bg-secondary mr-4">
                <i className="fa fa-2x fa-certificate text-white"></i>
              </div>
              <div className="mt-n1">
                <h4>Best risk management techniques </h4>
                <p>Upon successful completion traders are able to manage the risk properly</p>
              </div>
            </div>
            <div className="d-flex">
              <div className="btn-icon bg-warning mr-4">
                <i className="fa fa-2x fa-book-reader text-white"></i>
              </div>
              <div className="mt-n1">
                <h4>Online Classes</h4>
                <p>Enjoy the convenience of learning from anywhere with our online classes, designed to fit your schedule and lifestyle.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: '500px' }}>
            <div className="position-relative h-100">
              <img className="position-absolute w-100 h-100" src={FeatureImg} style={{ objectFit: 'cover' }} alt="Feature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
