import React from "react";
import Topbar from '../../components/Topbar';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import swingTradingImage from '../../assets/img/pro-trader.webp';

export default function ProTraderProgram() {
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12">
            <img src={swingTradingImage} className="img-fluid mb-3 program-image" alt="Swing Trading Program" />
            <h3 className="mb-3 title">Invfly Pro Trader Program</h3>
            <div className="quote-banner">
              <h5 className="lead quote">Aspire to become a proficient day trader? The Invfly Pro Trader Program equips you with the knowledge, skills, strategies, and proper risk management to navigate the fast-paced world of day trading</h5>
            </div>
            <h4 className="mt-4 mb-3">What You'll Learn:</h4>
            <ul className="points">
              <li className="point"><strong className="text-info">Day Trading Fundamentals:</strong> Master the core concepts of day trading, including market structure, order types, and chart analysis.</li>
              <li className="point"><strong className="text-info">Technical Analysis:</strong> Uncover the secrets of technical analysis, learning to interpret charts, identify trading signals, and make informed decisions based on price action and technical indicators.</li>
              <li className="point"><strong className="text-info">Day Trading Strategies helps to make Profits:</strong> Explore a diverse range of day trading strategies that allow you to find the approach that best suits your trading style and risk tolerance levels.</li>
              <li className="point"><strong className="text-info">Advanced Concepts:</strong> Delve deeper into advanced topics like risk management, position sizing, and options trading, enabling you to refine your trading strategies and manage risk effectively.</li>
              <li className="point"><strong className="text-info">Live Market Experience:</strong> Gain valuable practical experience through simulated and live trading sessions, putting your knowledge into action under real-time market conditions.</li>
            </ul>
            <h4 className="mt-4 mb-3">Beyond this we also do:</h4>
            <p>The Invfly Pro Trader Program goes beyond simply teaching you trading strategies. We focus on</p>
            <ul className="points">
              <li className="point"><strong className="text-info">Providing the Trading Journal - </strong>we are offering the students the advanced trading journal for recording their trades on trade basis for analysing their performance.</li>
              <li className="point"><strong className="text-info">Evaluation of the Trader - </strong>we are evaluating the performance of the trader based on their Journal and updating about their lacking areas for wrong trades.</li>
              <li className="point"><strong className="text-info">Hand holding Approach - </strong>we are analysing the trader performance for at least 6 months before starting own trading with less capital by applying all the strategies learned in classroom.</li>
              <li className="point"><strong className="text-info">Trading Psychology - </strong>Develop the mental fortitude and emotional control necessary to navigate the emotional swings of the market.</li>
              <li className="point"><strong className="text-info">Community Support - </strong>: Connect with a supportive community of fellow traders, share experiences, and learn from each other's successes and challenges, which helps the traders to get Knowledge sharing.</li>
            </ul>
            <h4 className="mt-4 mb-3">This program is designed for individuals who are:</h4>
            <ul className="points">
              <li className="point"><strong className="text-info">Serious about day trading: </strong>Committed to learning and actively pursuing day trading as a potential income source.</li>
              <li className="point"><strong className="text-info">Willing to dedicate time and effort: </strong>Day trading requires consistent learning, practice, and discipline.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}